import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import SEO from '../components/seo';
import MainLayout from '../layouts/MainLayout';
import CSSReset from '../misc/CSSReset';

const NotFoundPage = () => (
	<MainLayout>
		<CSSReset />
		<SEO title="404: Not found" />
		<Wrapper>
			Not found! <Link to="/">Try with the homepage</Link>.
		</Wrapper>
	</MainLayout>
);

const Wrapper = styled.div`
	background: #ffffff;
	border-radius: 2px;
	padding: 1rem;
	font-size: 1rem;
	text-align: center;
	
	a {
	  text-decoration: underline;
	}
`;

export default NotFoundPage;
